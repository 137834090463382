var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.tableLoading, rounded: "sm" } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                _c("span", { staticClass: "heading" }, [
                  _vm._v(
                    " Welcome back, " + _vm._s(_vm.user.first_name) + " 👋 "
                  )
                ])
              ]),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "7" } },
                [
                  _c("b-row", { staticClass: "mt-n0 ml-0 mr-0 float-right" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center mr-3 mb-1" },
                        [
                          _c(
                            "b-avatar",
                            {
                              attrs: { variant: "light-primary", rounded: "" }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "UsersIcon", size: "18" }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-1" }, [
                            _c(
                              "h4",
                              { staticClass: "mb-0 font-weight-bolder " },
                              [_vm._v(_vm._s(_vm.summery.staff) + " ")]
                            ),
                            _c("small", [_vm._v("Total Staff")])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center mr-3 mb-1" },
                        [
                          _c(
                            "b-avatar",
                            {
                              attrs: { variant: "light-primary", rounded: "" }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "CoffeeIcon", size: "18" }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-1" }, [
                            _c(
                              "h4",
                              { staticClass: "mb-0 font-weight-bolder" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.summery.todayCount) + " "
                                )
                              ]
                            ),
                            _c("small", [_vm._v("On leave today ")])
                          ])
                        ],
                        1
                      )
                    ])
                  ])
                ],
                1
              ),
              _c("b-col", { attrs: { cols: "12", md: "2" } }, [
                _c(
                  "div",
                  { staticClass: "flex flex-wrap float-right mb-2 mb-md-0" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass:
                          "btn-icon shadow-sm bg-white text-primary mr-1",
                        attrs: { variant: "outline-white" },
                        on: {
                          click: function($event) {
                            return _vm.refresh()
                          }
                        }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "RefreshCwIcon", size: "18" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-icon shadow-sm bg-white text-primary",
                        attrs: { variant: "outline-white" },
                        on: {
                          click: function($event) {
                            return _vm.toggleSideBars()
                          }
                        }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "SettingsIcon", size: "18" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "match-height mt-5" },
            [
              this.leaves_rolling === 1
                ? _c("b-col", { attrs: { cols: "12" } }, [
                    _c("div", { staticClass: "d-flex flex-row pt-1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "items",
                          staticStyle: { "padding-top": "8px" }
                        },
                        [_vm._v(" Leaves ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "ml-auto" },
                        [
                          _c("b-dropdown", {
                            staticClass: "mr-0",
                            attrs: {
                              dropleft: "",
                              text: "Drop-Left",
                              variant: "link",
                              "toggle-class": "text-decoration-none",
                              "no-caret": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        staticStyle: { color: "#B2B2B2" },
                                        attrs: {
                                          icon: "MoreVerticalIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              3819152207
                            )
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "mt-1" }, [_c("Leaves")], 1)
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "match-height mt-5" },
            [
              this.shift_applications === 1
                ? _c("b-col", { attrs: { lg: "4", md: "6" } }, [
                    _c("div", { staticClass: "d-flex flex-row pt-1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "items",
                          staticStyle: { "padding-top": "8px" }
                        },
                        [_vm._v(" Shift Applications ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "ml-auto" },
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "mr-0",
                              attrs: {
                                dropleft: "",
                                text: "Drop-Left",
                                variant: "link",
                                "toggle-class": "text-decoration-none",
                                "no-caret": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          staticStyle: { color: "#B2B2B2" },
                                          attrs: {
                                            icon: "MoreVerticalIcon",
                                            size: "18"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3819152207
                              )
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "p-0",
                                      attrs: { variant: "outline-white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.updateDashboard(
                                            _vm.shift_value
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "mr-50",
                                        attrs: { icon: "EyeOffIcon" }
                                      }),
                                      _c("span", [
                                        _vm._v("Hide from dashboard")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "mt-1" },
                      [_c("ShiftApplications")],
                      1
                    )
                  ])
                : _vm._e(),
              this.leaves_rolling === 1
                ? _c("b-col", { attrs: { lg: "4", md: "6" } }, [
                    _c("div", { staticClass: "d-flex flex-row pt-1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "items",
                          staticStyle: { "padding-top": "8px" }
                        },
                        [_vm._v(" Leave requests ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "ml-auto pr-0 mr-0" },
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "mr-0",
                              attrs: {
                                dropleft: "",
                                text: "Drop-Left",
                                variant: "link",
                                "toggle-class": "text-decoration-none",
                                "no-caret": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          staticStyle: { color: "#B2B2B2" },
                                          attrs: {
                                            icon: "MoreVerticalIcon",
                                            size: "18"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3819152207
                              )
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "p-0",
                                      attrs: { variant: "outline-white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.goToLeaveRequests()
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "mr-50",
                                        attrs: { icon: "ExternalLinkIcon" }
                                      }),
                                      _c("span", [_vm._v("View all")])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "p-0",
                                      attrs: { variant: "outline-white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.updateDashboard(
                                            _vm.leaves_rolling_value
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "mr-50",
                                        attrs: { icon: "EyeOffIcon" }
                                      }),
                                      _c("span", [
                                        _vm._v("Hide from dashboard")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "mt-1" }, [_c("LeaveRequests")], 1)
                  ])
                : _vm._e(),
              this.connection_requests === 1
                ? _c("b-col", { attrs: { lg: "4", md: "6" } }, [
                    _c("div", { staticClass: "d-flex flex-row pt-1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "items",
                          staticStyle: { "padding-top": "8px" }
                        },
                        [_vm._v(" Connection requests ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "ml-auto" },
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "mr-0",
                              attrs: {
                                dropleft: "",
                                text: "Drop-Left",
                                variant: "link",
                                "toggle-class": "text-decoration-none",
                                "no-caret": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          staticStyle: { color: "#B2B2B2" },
                                          attrs: {
                                            icon: "MoreVerticalIcon",
                                            size: "18"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3819152207
                              )
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "p-0",
                                      attrs: { variant: "outline-white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.goToConnectionRequests()
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "mr-50",
                                        attrs: { icon: "ExternalLinkIcon" }
                                      }),
                                      _c("span", [_vm._v("View all")])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "p-0",
                                      attrs: { variant: "outline-white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.updateDashboard(
                                            _vm.connection_requests_value
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "mr-50",
                                        attrs: { icon: "EyeOffIcon" }
                                      }),
                                      _c("span", [
                                        _vm._v("Hide from dashboard")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "mt-1" },
                      [_c("ConnectionRequests")],
                      1
                    )
                  ])
                : _vm._e(),
              this.time_sheets === 1
                ? _c("b-col", { attrs: { lg: "4", md: "6" } }, [
                    _c("div", { staticClass: "d-flex flex-row pt-1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "items",
                          staticStyle: { "padding-top": "8px" }
                        },
                        [_vm._v(" Timesheets ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "ml-auto" },
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "mr-0",
                              attrs: {
                                dropleft: "",
                                text: "Drop-Left",
                                variant: "link",
                                "toggle-class": "text-decoration-none",
                                "no-caret": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          staticStyle: { color: "#B2B2B2" },
                                          attrs: {
                                            icon: "MoreVerticalIcon",
                                            size: "18"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3819152207
                              )
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "p-0",
                                      attrs: { variant: "outline-white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.goToTimeSheet()
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "mr-50",
                                        attrs: { icon: "ExternalLinkIcon" }
                                      }),
                                      _c("span", [_vm._v("View all")])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "p-0",
                                      attrs: { variant: "outline-white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.updateDashboard(
                                            _vm.time_sheet_value
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "mr-50",
                                        attrs: { icon: "EyeOffIcon" }
                                      }),
                                      _c("span", [
                                        _vm._v("Hide from dashboard")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "mt-1" }, [_c("TimeSheets")], 1)
                  ])
                : _vm._e(),
              this.locum_requests === 1
                ? _c("b-col", { attrs: { lg: "4", md: "6" } }, [
                    _c("div", { staticClass: "d-flex flex-row pt-1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "items",
                          staticStyle: { "padding-top": "8px" }
                        },
                        [_vm._v(" Locum requests ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "ml-auto pr-0 mr-0" },
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "mr-0",
                              attrs: {
                                dropleft: "",
                                text: "Drop-Left",
                                variant: "link",
                                "toggle-class": "text-decoration-none",
                                "no-caret": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          staticStyle: { color: "#B2B2B2" },
                                          attrs: {
                                            icon: "MoreVerticalIcon",
                                            size: "18"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3819152207
                              )
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "p-0",
                                      attrs: { variant: "outline-white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.goToLocumRequests()
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "mr-50",
                                        attrs: { icon: "ExternalLinkIcon" }
                                      }),
                                      _c("span", [_vm._v("View all")])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "p-0",
                                      attrs: { variant: "outline-white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.updateDashboard(
                                            _vm.locum_request_value
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "mr-50",
                                        attrs: { icon: "EyeOffIcon" }
                                      }),
                                      _c("span", [
                                        _vm._v("Hide from dashboard")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "mt-1" }, [_c("LocumRequests")], 1)
                  ])
                : _vm._e(),
              this.locum_invoices === 1
                ? _c("b-col", { attrs: { lg: "4", md: "6" } }, [
                    _c("div", { staticClass: "d-flex flex-row pt-1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "items",
                          staticStyle: { "padding-top": "8px" }
                        },
                        [_vm._v(" Locum invoices ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "ml-auto" },
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "mr-0",
                              attrs: {
                                dropleft: "",
                                text: "Drop-Left",
                                variant: "link",
                                "toggle-class": "text-decoration-none",
                                "no-caret": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          staticStyle: { color: "#B2B2B2" },
                                          attrs: {
                                            icon: "MoreVerticalIcon",
                                            size: "18"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3819152207
                              )
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "p-0",
                                      attrs: { variant: "outline-white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.gotoLocumInvoices()
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "mr-50",
                                        attrs: { icon: "ExternalLinkIcon" }
                                      }),
                                      _c("span", [_vm._v("View all")])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "p-0",
                                      attrs: { variant: "outline-white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.updateDashboard(
                                            _vm.locum_invoice_value
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "mr-50",
                                        attrs: { icon: "EyeOffIcon" }
                                      }),
                                      _c("span", [
                                        _vm._v("Hide from dashboard")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "mt-1" }, [_c("LocumInvoices")], 1)
                  ])
                : _vm._e(),
              this.swap_shift === 1
                ? _c("b-col", { attrs: { lg: "4", md: "6" } }, [
                    _c("div", { staticClass: "d-flex flex-row pt-1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "items",
                          staticStyle: { "padding-top": "8px" }
                        },
                        [_vm._v(" Swap Shifts ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "ml-auto" },
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "mr-0",
                              attrs: {
                                dropleft: "",
                                text: "Drop-Left",
                                variant: "link",
                                "toggle-class": "text-decoration-none",
                                "no-caret": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          staticStyle: { color: "#B2B2B2" },
                                          attrs: {
                                            icon: "MoreVerticalIcon",
                                            size: "18"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3819152207
                              )
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "p-0",
                                      attrs: { variant: "outline-white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.gotoLocumInvoices()
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "mr-50",
                                        attrs: { icon: "ExternalLinkIcon" }
                                      }),
                                      _c("span", [_vm._v("View all")])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "p-0",
                                      attrs: { variant: "outline-white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.updateDashboard(
                                            _vm.swap_shift_value
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "mr-50",
                                        attrs: { icon: "EyeOffIcon" }
                                      }),
                                      _c("span", [
                                        _vm._v("Hide from dashboard")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "mt-1" }, [_c("SwapShift")], 1)
                  ])
                : _vm._e()
            ],
            1
          ),
          _c("DashboardSettingsSidebar", {
            ref: "AddLeaveSidebar",
            attrs: {
              "is-dashboard-settings-sidebar-active":
                _vm.isDashboardSettingsSidebarActive,
              "shift-applications": _vm.shift_applications,
              "connection-requests": _vm.connection_requests,
              "time-sheets": _vm.time_sheets,
              "leave-requests": _vm.leaves_rolling,
              "locum-requests": _vm.locum_requests,
              "locum-invoices": _vm.locum_invoices,
              leaves_rolling: _vm.leaves_rolling
            },
            on: {
              "update:isDashboardSettingsSidebarActive": function($event) {
                _vm.isDashboardSettingsSidebarActive = $event
              },
              "update:is-dashboard-settings-sidebar-active": function($event) {
                _vm.isDashboardSettingsSidebarActive = $event
              },
              updateOfDashboard: _vm.showDashboardItems
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }