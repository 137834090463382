<template>
  <div>
      <b-card no-body>
            <b-card-body class=" pl-1 ">
                <b-tabs>
                    <b-tab lazy>
                     <template #title>
                      <span class="" ><strong>Pending requests</strong>
                      <span v-if="pendingRequestCount>0" class="ml-1 translate-middle badge rounded-circle bg-danger"
                          style="font-size: 0.8em;">{{pendingRequestCount}}</span>
                      </span>
                     </template>
                        <div class="alert-body"> <PendingRequests
                                                    />
                        </div>
                    </b-tab>
                    <b-tab lazy>
                     <template #title>
                      <span class="" ><strong>Today's leaves ({{todaysLeaveCount}})</strong>
                      </span>
                     </template>
                        <div class="alert-body"> <TodaysLeaves />
                        </div>
                    </b-tab>
                    <b-tab lazy>
                     <template #title>
                      <span class="" ><strong>On leave in the next 4 weeks ({{leaveRolling}})</strong>
                      </span>
                     </template>
                        <div class="alert-body"> <WeeklyRolling
                                                    />
                        </div>
                    </b-tab>
                </b-tabs>
            </b-card-body>
        </b-card>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BCard, BCardBody, BCardHeader, BCol, BImg, BMedia, BRow, BPagination,
  BOverlay,
  BTable, VBToggle, VBTooltip,BTabs , BTab,
} from 'bootstrap-vue'
import MomentMixin from '@/mixins/MomentMixin'
import Ripple from 'vue-ripple-directive'
import AdminLeaveAPI from '@/apis/modules/leave_managment_admin'
import TodaysLeaves from '../leavesTables/todayLeaves.vue'
import PendingRequests from '../leavesTables/leavesRequests.vue'
import WeeklyRolling from '../leavesTables/weeklyRollingLeaves.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar';


export default {
  components: {
    TodaysLeaves,
    PendingRequests,
    WeeklyRolling,
    BMedia,
    BImg,
    BTabs, 
    BTab,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BBadge,
    BPagination,
    BOverlay,
    BTable,
    VBTooltip,
    VBToggle,
    VuePerfectScrollbar
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip
  },
  mixins: [MomentMixin],
  data () {
    return {
      fields: [
        {
          key: 'name',
          label: ''
        },

        {
          key: 'action',
          label: ''
        }
      ],
      items: [],
      leaveRequests: [],
      tableLoading: false,
      noDataTable: '',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      todaysLeaveCount:0,
      leaveRolling:0,
      pendingRequestCount:0,

    }
  },
  methods: {
    async getSummary(){
        try{
            const Response = await AdminLeaveAPI.summary()
            this.pendingRequestCount = Response.data.data.pending_request_count
            this.todaysLeaveCount = Response.data.data.todays_leave_count
            this.leaveRolling = Response.data.data.leave_rolling
        }catch(error){
            this.convertAndNotifyError(error)
        }
    },


  },

  mounted () {
      this.getSummary()
  }
}
</script>

<style>
.table_shifts table thead{
  display: none !important;
}

.pagination_style{
  margin-top: 450px;
}

.space {
  margin-bottom: 8px;
}

</style>