var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-card-body",
            { staticClass: " pl-1 " },
            [
              _c(
                "b-tabs",
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { lazy: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c("span", {}, [
                                _c("strong", [_vm._v("Pending requests")]),
                                _vm.pendingRequestCount > 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "ml-1 translate-middle badge rounded-circle bg-danger",
                                        staticStyle: { "font-size": "0.8em" }
                                      },
                                      [_vm._v(_vm._s(_vm.pendingRequestCount))]
                                    )
                                  : _vm._e()
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "alert-body" },
                        [_c("PendingRequests")],
                        1
                      )
                    ]
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: { lazy: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c("span", {}, [
                                _c("strong", [
                                  _vm._v(
                                    "Today's leaves (" +
                                      _vm._s(_vm.todaysLeaveCount) +
                                      ")"
                                  )
                                ])
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "alert-body" },
                        [_c("TodaysLeaves")],
                        1
                      )
                    ]
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: { lazy: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c("span", {}, [
                                _c("strong", [
                                  _vm._v(
                                    "On leave in the next 4 weeks (" +
                                      _vm._s(_vm.leaveRolling) +
                                      ")"
                                  )
                                ])
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "alert-body" },
                        [_c("WeeklyRolling")],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }