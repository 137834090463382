var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vue-perfect-scrollbar",
        {
          staticClass:
            "todo-task-list-wrapper list-group scroll-area min-vh-50",
          staticStyle: { "max-height": "500px" }
        },
        _vm._l(_vm.leaveRequests, function(leaveRequest) {
          return _c("b-card", { staticClass: "space mr-1" }, [
            _c(
              "div",
              { staticClass: "d-flex " },
              [
                _c("b-avatar", {
                  attrs: {
                    size: "40",
                    src: _vm.getUserImage(leaveRequest.user_id)
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "ml-1",
                    staticStyle: { "padding-top": "2px" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "14px",
                          color: "#171822",
                          "font-family": "Mulish"
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(leaveRequest.date) +
                              ", " +
                              _vm._s(leaveRequest.time)
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-family": "Mulish",
                          "font-size": "12px",
                          color: "#949494"
                        }
                      },
                      [
                        _c("span", { staticClass: "mr-1" }, [
                          _vm._v(" " + _vm._s(leaveRequest.reason) + " ")
                        ])
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "float-end",
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "row",
                      "margin-left": "auto"
                    }
                  },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.top",
                            value: "Accept",
                            expression: "'Accept'",
                            modifiers: { hover: true, top: true }
                          }
                        ],
                        staticClass:
                          "btn-icon shadow-sm bg-white text-primary p-1",
                        attrs: { size: "23", variant: "outline-white" },
                        on: {
                          click: function($event) {
                            return _vm.acceptLeave(leaveRequest.id)
                          }
                        }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "CheckSquareIcon", size: "18" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.top",
                            value: "Reject",
                            expression: "'Reject'",
                            modifiers: { hover: true, top: true }
                          }
                        ],
                        staticClass:
                          "btn-icon ml-1 shadow-sm bg-white text-danger p-1",
                        attrs: { size: "23", variant: "outline-white" },
                        on: {
                          click: function($event) {
                            return _vm.rejectLeave(leaveRequest.id)
                          }
                        }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "XSquareIcon", size: "18" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        }),
        1
      ),
      _c(
        "div",
        [
          _vm.noDataTable === 0
            ? _c(
                "b-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c("div", { staticClass: "empty-state py-5" }, [
                    _c("img", {
                      staticClass: "img-fluid",
                      staticStyle: { width: "160px", height: "160px" },
                      attrs: {
                        src: require("@/assets/images/pages/leave-icon.png"),
                        alt: ""
                      }
                    }),
                    _c("p", { staticClass: "txt-grey" }, [_vm._v("No Leaves")])
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }